import React from "react"
import { Box, Grid, Paper, Typography } from "@material-ui/core"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import Seo from "../components/global/seo/seo.jsx"
function Iletisim() {
  return (
    <Layout>
      <Seo
        title="İletişim - Paslanmaz Filtre Erez Kaynak"
        description="İletişim Bilgilerimiz Firma Ünvanı : Erez Kaynak Uğur Erkin DEMİRKAYA Adres : İkitelli Org.San.Bölgesi Dersan Koop San.Sit. Trios 2023 B Blok Kat 3 No:137Başakşehir / İSTANBUL İkitelli VD. V.No: 31198823454 Mersis No: 3119882345400001 Ticaret Sicil No: 102885-5 Tel : 0212 549 36 20GSM :0535 222 95 57 Mail:"
        keywords="paslanmaz filtre imalatı iletişim bilgileri"
        url="iletisim"
      />
      <Box mt={3}>
        <Paper elevation={4}>
          <Typography variant="h4" component="h1">
            <Box pt={3} textAlign="center" fontWeight={500}>
              Firma Bilgilerimiz
            </Box>
          </Typography>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box p={3}>
                <Lead>
                  <span>Firma Ünvanı: </span>Erez Kaynak Uğur Erkin DEMİRKAYA
                  <br />
                  <span>Adres: </span>İkitelli Org.San.Bölgesi Dersan Koop
                  San.Sit. Trios 2023 B Blok Kat 3 No:137 Başakşehir / İSTANBUL
                  <br />
                  <span>İkitelli VD. V.No: </span>31198823454
                  <br />
                  <span>Mersis No: </span>3119882345400001
                </Lead>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box p={3}>
                <Lead>
                  <span>Ticaret Sicil No: </span>102885-5
                  <br />
                  <span>Tel: </span>
                  <a className="footer-link" href="tel://02125493620">
                    0212 549 36 20
                  </a>
                  <br />
                  <span>GSM: </span>
                  <a className="footer-link" href="tel://05352229557">
                    0535 222 95 57
                  </a>
                  <br />
                  <span>Mail: </span>
                  <a
                    className="footer-link"
                    href="mailto:info@erezkaynak.com"
                    target="_top"
                  >
                    info@erezkaynak.com
                  </a>
                </Lead>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box mt={3}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper elevation={4}>
              <Typography variant="h4" component="h2">
                <Box py={3} textAlign="center" fontWeight={500}>
                  Google Haritada Yerimiz
                </Box>
              </Typography>
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.367142269452!2d28.787222015048798!3d41.08282422278367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa58abefcc16d%3A0x27f8a2b4aee2b1!2zRXJleiBLYXluYWsgw4dlbGlrIEhhc8SxciBUZWwgdmUgRmlsdHJlIEVsZWsgxLBtYWxhdMOnxLFzxLE!5e0!3m2!1str!2str!4v1575991627428!5m2!1str!2str"
                  width="100%"
                  height="400"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                ></iframe>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper elevation={4}>
              <Typography variant="h4" component="h2">
                <Box py={3} textAlign="center" fontWeight={500}>
                  Yandex Haritada Yerimiz
                </Box>
              </Typography>
              <Box>
                <iframe
                  src="https://yandex.com.tr/map-widget/v1/?z=12&ol=biz&oid=91911551456"
                  width="100%"
                  height="400"
                  frameborder="0"
                ></iframe>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}
export default Iletisim
